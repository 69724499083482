import React, { useCallback, useEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  Avatar,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { RiderKycApi } from "../../../API/RiderKycApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router";
import redCall from "../../../assets/icons/red-phone.svg";
import view from "../../../assets/icons/eye-view.svg";
import download from "../../../assets/icons/download.svg";
import { useLocation } from "react-router";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";

export default function RiderDetailKyc() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedClient, setSelectedClient] = useState<any>();
  const [clientid, setClientID] = useState<any>();
  const [mappedNumber, setMappedNumber] = useState<any>();
  const [selectedArea, setSelectedArea] = useState<any>();
  const [selectedTl, setSelectedTl] = useState<any>();
  const [merchantList, setMerchantList] = useState<any[]>([]);
  const [merchantListStore, setMerchantListStore] = useState<any[]>([]);
  const [riderDetails, setRiderDetails] = useState<any>({});
  const [error, setError] = useState<string>("");
  const [errorVerify, setErrorVerify] = useState<string>("");
  const [verifysucess, setVerifysucess] = useState<string>("");
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const [tlOptions, setTlOptions] = useState<any[]>([]);
  const merchantIdpreflied = location.state?.merchantId;
  const [isClientChanged, setIsClientChanged] = useState(false);
  const [isClientIDChanged, setIsClientIDChanged] = useState(false);
  const [isClientIDVerified, setIsClientIDVerified] = useState(false); // New state variable for Client ID verification
  const [userDetail, setUserDetail] = useState<any>({}); // New state variable for Client ID verification


  const userid = location.state?.id
  const requestId = location.state?.userid

  useEffect(() => {
    RiderKycApi.api?.riderDetailsKyc(userid)
      .then((response) => {
        setUserDetail(response.data);


      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, []);


  useEffect(() => {
    const fetchMerchantList = async () => {
      try {
        const response = await RiderKycApi.api.merchantListHyperTrack(requestId);
        if (response.data && response.data.length > 0) {
          setMerchantList(response.data);
        }
      } catch (error) {
        console.error("Error fetching merchant list:", error);
      }
    };

    fetchMerchantList();
  }, []);
  console.log('riderDetails', riderDetails)
  useEffect(() => {


    const fetchMerchantData = async (merchantid: number | null | undefined, userid: any) => {
      try {
        // Array to hold all promises
        const promises = [];

        if (merchantid) {
          // Fetch stores based on merchantId
          promises.push(RiderKycApi.api.merchantStoreListHyperTrack(merchantid));

          // Fetch TL options
          promises.push(fetch(`https://zypp-recommend.bcykal.com/get_tl_ids/?userId=${userid}&merchantId=${merchantid}`).then(response => response.json()));
        }

        // Fetch rider details
        const riderDetailsPromise = axios.get(`https://beta.bcykal.com/mobycy/clientDash/v1/userDetail/userId?id=${location.state?.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        promises.push(riderDetailsPromise);

        // Wait for all promises to resolve
        const [storeResponse, tlData, riderResponse] = await Promise.all(promises);

        if (storeResponse) {
          setMerchantListStore(storeResponse.data || []);
        }

        if (tlData) {
          setTlOptions(tlData || []);
        }

        if (riderResponse) {
          setRiderDetails(riderResponse.data.data);
        }

        if (merchantIdpreflied) {
          setSelectedClient({
            merchantId: typeof merchantIdpreflied === 'string' ? parseInt(merchantIdpreflied, 10) : merchantIdpreflied,
            merchantName: merchantList.find((m: { merchantId: any; }) => m.merchantId === (typeof merchantIdpreflied === 'string' ? parseInt(merchantIdpreflied, 10) : merchantIdpreflied))?.merchantName,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


    // Ensure merchantIdpreflied is a number
    const merchantId = typeof merchantIdpreflied === 'string' ? parseInt(merchantIdpreflied, 10) : merchantIdpreflied;
    //fetchMerchantData(merchantId, location.state?.userid);
    if (location.state?.id) {
      fetchMerchantData(merchantId, location.state?.userid);
    }

  }, [merchantIdpreflied, location.state, merchantList]);

  const handleMerchantChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedArea(null); // Reset selected area when client changes

    setVerifysucess("");
    //setSelectedClient(newClient); 
    setIsClientIDChanged(true);
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedMerchantIdString = selectedOption.getAttribute("data-merchant-id");
    const selectedMerchantId = parseInt(selectedMerchantIdString || '', 10); // Convert to number
    const selectedMerchantName = selectedOption.getAttribute("data-merchant-name");

    setSelectedClient({
      merchantId: selectedMerchantId,
      merchantName: selectedMerchantName,
    });
    setIsClientChanged(true);
    setSelectedArea(null);

    if (!isNaN(selectedMerchantId)) { // Ensure it's a valid number
      try {
        const storeResponse = await RiderKycApi.api.merchantStoreListHyperTrack(selectedMerchantId);
        setMerchantListStore(storeResponse.data || []);
        setSelectedArea(null); // Reset selected area when client changes

      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    }
  };
  const downloadAllDocuments = () => {
    const documentIds = [
      riderDetails.aadharFront,
      riderDetails.aadharBack,
      riderDetails.pan,
      riderDetails.dl,
    ];

    documentIds.forEach((id) => {
      if (id) {
        const documentUrl = `https://dash.bcykal.com/mobycy/api/media/file/${id}`;
        const link = document.createElement("a");
        link.href = documentUrl;
        link.download = id;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  function createData(name: string, id: any) {
    return { name, id };
  }

  const documents = [
    createData("Aadhar Card Front", riderDetails.aadharBack),
    createData("Aadhar Card Back", riderDetails.aadharFront),
    createData("Pan Card", riderDetails.pan),
    createData("Driving Licence", riderDetails.dl),
  ];

  const viewDocument = (id: any) => {
    const documentUrl = `https://dash.bcykal.com/mobycy/api/media/file/${id}`;
    setImageUrl(documentUrl);
    setImageModalOpen(true);
  };

  const downloadDocument = (id: any) => {
    const documentUrl = `https://dash.bcykal.com/mobycy/api/media/file/${id}`;
    const link = document.createElement("a");
    link.href = documentUrl;
    link.download = id;
    link.click();
  };

  const token = localStorage.getItem("access_token");
  console.log('selectedArea', selectedArea)
  const assignTl = async () => {
    if (!selectedTl && location.state?.tlEnable === 1) {
      setError("Please select Rider’s TL");
      return;
    }
    if (!mappedNumber) {
      setError("Please Enter Client ID Mapped Phone Number");
      return;
    }
    setError("")
    const requestData = {
      id: location.state?.id,
      clientId: clientid,
      storeId: selectedArea,
      merchantId: selectedClient?.merchantId,
      merchantName: selectedClient?.merchantName,
      tlId: selectedTl,
      phone: mappedNumber,
    };

    try {
      const response = await fetch("https://beta.bcykal.com/mobycy/clientDash/v1/create/client/request/update", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const result = await response.json();

      if (result.code === 2000) {
        setError("")
        navigate("/riderKyc");
      } else {
        setError(result.message || "Submit failed.");
      }
    } catch (error) {
      setError("An error occurred while assigning TL.");
    }
  };

  const verifyClientID = async () => {
    const userid = location.state?.userid;
    if (!clientid || !selectedClient || !userid || !selectedArea) {
      setError("Please Select Rider’s Interested Area");
      return;
    }
    setError("");

    try {
      const response = await fetch(
        `https://dash.bcykal.com/mobycy/api/v1/verify/clientId/googleSheet?clientUserId=${clientid}&merchantId=${selectedClient?.merchantId}&userId=${userid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      setErrorVerify(""); // Clear error message if verification is successful


      if (result.code === 2000) {
        setError("");
        setVerifysucess("Client ID verified successfully.");
        setIsClientIDVerified(true);  // Mark the client ID as verified
        setIsClientIDChanged(false);  // Mark Client ID as unchanged after verification
      } else {
        setError("");
        setVerifysucess("");
        console.log('selectedarea', selectedArea);
        setSelectedArea(""); // Reset selected area when client changes

        setErrorVerify(result.message || "Verification failed.");
      }
    } catch (error) {
      setErrorVerify("An error occurred while verifying Client ID.");
    }
  };

  const isSubmitDisabled = !isClientIDVerified || isClientIDChanged || !clientid || !selectedClient || !selectedArea;


  return (
    <>
      <div className="bg-gradient-to-r from-slate-100 to-slate-50 pl-8 pr-8">
        <div style={{ height: "40px", width: "100%" }}>
          <Toolbar
            sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}
            className="bg-gradient-to-r from-slate-100 to-slate-50"
          >
            <Typography
              sx={{ flex: "1 1 75%", textAlign: "left" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              <a onClick={() => navigate("/tlcmmapping")} className="cursor-pointer">
                <ArrowBackIcon className="mr-4 mb-1 ml-7" />
              </a>
              <b>Create User</b>
            </Typography>

            <Typography
              sx={{
                flex: "1 1 100%",
                textAlign: "right",
                marginRight: "48px",
              }}
              component="div"
            >
              <HomeIcon className="mb-1" />
              {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span> {" / "}
              Create User
            </Typography>
          </Toolbar>
        </div>
        <div className="bg-[#fff] p-8 rounded-lg pb-4 mb-4">
          <Grid container spacing={5} className="w-full mt-4 flex justify-between">
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">User Name</p>
                <TextField
                  id="outlined-basic"
                  label="User Name"
                  variant="outlined"
                  className="w-full"
                  value={userDetail?.name || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">Employee ID</p>
                <TextField
                  id="outlined-basic"
                  label="Employee ID"
                  variant="outlined"
                  className="w-full"
                  value={userDetail.mobile || ""}
                  InputProps={{
                    readOnly: true,

                  }}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">User Mobile Number</p>
                <TextField
                  id="outlined-basic"
                  label="Mobile Number"
                  variant="outlined"
                  className="w-full"
                  value={userDetail.mobile || ""}
                  InputProps={{
                    readOnly: true,

                  }}
                />
              </div>
            </Grid>
          </Grid>
          <hr className="my-7" />
          <Grid container spacing={5} className="w-full flex justify-between">
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">User Email<sup style={{ color: 'red' }}>*</sup></p>
                <TextField
                  id="outlined-basic"
                  label="User Email"
                  variant="outlined"
                  className="w-full"
                  value={userDetail.mobile || ""}
                  InputProps={{
                    readOnly: true,

                  }}
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-full mt-4">
                <p className="text-left mb-4 text-sm font-bold">
                  User City <sup style={{ color: 'red' }}>*</sup>
                </p>
                <FormControl fullWidth>
                  <select
                    id="city"
                    onChange={(e) => {
                      setSelectedArea(e.target.value);
                    }}
                    style={{
                      border: 1,
                      borderColor: "#c0baba",
                      borderStyle: "solid",
                      padding: 7,
                      borderRadius: 3,
                      height: 56,
                    }}
                    disabled={!merchantListStore.length}

                  >
                    <option value="" selected>Select Area</option> {/* Default option */}

                    {merchantListStore.map((merchant, index) => (
                      <option key={index} value={merchant.storeId}>
                        {merchant.storeName}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">User Password <sup style={{ color: 'red' }}>*</sup></p>
                <TextField
                  id="outlined-basic"
                  label="User Password"
                  variant="outlined"
                  className="w-full"
                  value={clientid}
                  onChange={(e) => {
                    setClientID(e.target.value);
                    setIsClientIDChanged(true);
                    setIsClientIDVerified(false); // Reset verification if Client ID is changed
                    setVerifysucess("");


                  }}
                  error={!!errorVerify}

                  helperText={errorVerify}
                />
                <p className="text-left text-[#00cd5b]">{verifysucess}</p>
              </div>
            </Grid>
          </Grid>
          <hr className="my-7" />
          <Grid container spacing={5} className="w-full flex justify-between">
            <Grid item xs={4}>
              <div>
                <p className="text-left mb-4 text-sm font-bold">User Location <sup style={{ color: 'red' }}>*</sup></p>
                <TextField
                  id="outlined-basic"
                  label="User Location"
                  variant="outlined"
                  className="w-full"
                  type="number" // Set input type to number
                  inputProps={{
                    maxLength: 10, // Max length of 10 digits
                    min: 0, // Minimum value (to avoid negative numbers)
                    step: 1 // Step value to ensure whole numbers
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Only allow numbers up to 10 digits
                    if (value.length <= 10) {
                      setMappedNumber(value);
                    }
                  }}
                  value={mappedNumber}
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <div>
                <button
                  onClick={assignTl}
                  className={`px-12 py-4 mt-8 text-white rounded-lg w-full ${isSubmitDisabled ? "bg-gray-400" : "bg-[#FE5B44]"
                    }`}
                  disabled={isSubmitDisabled}
                  style={{
                    cursor: isSubmitDisabled ? 'not-allowed' : 'pointer',
                    opacity: isSubmitDisabled ? 0.6 : 1
                  }}
                >
                  Submit
                </button>
                {error && <Typography color="error">{error}</Typography>}
              </div>
            </Grid>
          </Grid>

        </div>
      </div>
    </>
  );
}
