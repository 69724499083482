import axios, { AxiosInstance, AxiosResponse } from "axios";
// import { response } from "express";
import {
  LoginResponse,
  MapOnRiderResponse,
  TopZyppRidersResponse,
  HotRoutesResponse,
  CityWiseSplitResponse,
  RiderEvAndDeliveriesResponse,
  CarbonSavedResponse,
  BarChartResponse,
  NewsCardInfoResponse,
  TickerDataResponse,
  TeamAndSpocsResponse,
  NotificationAlert,
  NotificationResponse,
  FleetGrownJourneyResponse,
  hubFleetRequestListResponse,
  hubListResponse,
  hubFleetManagerListResponse,
} from "../@Types/DRO/ApiResponses";

export class ClientApi {
  public static api = new ClientApi();
  private instance: AxiosInstance;
  static googleMapsOnDutyRiders() {
    throw new Error("Method not implemented.");
  }
  constructor() {
    this.instance = axios.create({
      baseURL: "https://beta.bcykal.com/mobycy/clientDash/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  formatDate = (date: Date | null): string | null => {
    if (!date) return null;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adjust month to be 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  onlineUserAuthentication(
    username: string,
    password: string
  ): Promise<AxiosResponse<LoginResponse>> {
    return axios.post<LoginResponse>("https://beta.bcykal.com/mobycy/login", {
      username,
      password,
    });
  }
  getToken() {
    return localStorage.getItem("access_token");
  }

  googleMapsOnDutyRiders(): Promise<MapOnRiderResponse[]> {
    return this.instance
      .get<MapOnRiderResponse[]>("get/onDuty/riders", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  carbonSavedData(
    startDate: Date | null,
    endDate: Date | null
  ): Promise<CarbonSavedResponse> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    let apiUrl = "get/data/carbon-saved";
    if (endDate) {
      apiUrl += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }
    return this.instance
      .get<CarbonSavedResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  treeSavedData(
    startDate: Date | null,
    endDate: Date | null
  ): Promise<CarbonSavedResponse[]> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    let apiUrl = "get/data/trees-saved";
    if (endDate) {
      apiUrl += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }
    return this.instance
      .get<CarbonSavedResponse[]>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }

  deliveriesDoneData(
    startDate: Date | null,
    endDate: Date | null
  ): Promise<CarbonSavedResponse[]> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    let apiUrl = "get/data/total-deliveries";
    if (endDate) {
      apiUrl += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }

    return this.instance
      .get<CarbonSavedResponse[]>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }

  riderEvAndTotalDeleveries(
    startDate: Date | null,
    endDate: Date | null
  ): Promise<RiderEvAndDeliveriesResponse> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    let apiUrl = "get/data/allotted-riders";
    if (endDate) {
      apiUrl += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }
    return this.instance
      .get<RiderEvAndDeliveriesResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  barChartApi(): Promise<BarChartResponse[]> {
    return this.instance
      .get<BarChartResponse[]>("get/rider-rideAge-data", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  newsCardInfo(): Promise<NewsCardInfoResponse> {
    return this.instance
      .get<NewsCardInfoResponse>("get/city/data/news", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  notificationList(): Promise<NotificationResponse> {
    return this.instance
      .get<NotificationResponse>("notifications", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  notificationalert(): Promise<NotificationAlert> {
    return this.instance
      .get<NotificationAlert>("notification/alert", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  topZyppRiders(): Promise<TopZyppRidersResponse> {
    return this.instance
      .get<TopZyppRidersResponse>("get/data/top-riders", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }

  hotRoutes(
    startDate: Date | null,
    endDate: Date | null
  ): Promise<HotRoutesResponse> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    let apiUrl = "get/data/hot-route";
    if (endDate) {
      apiUrl += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }
    return this.instance
      .get<HotRoutesResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }

  cityWiseSplit(
    startDate: Date | null,
    endDate: Date | null
  ): Promise<CityWiseSplitResponse> {
    const formattedStartDate = startDate ? this.formatDate(startDate) : null;
    const formattedEndDate = endDate ? this.formatDate(endDate) : null;
    let apiUrl = "get/city/data/city-order-density";
    if (endDate) {
      apiUrl += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }
    return this.instance
      .get<CityWiseSplitResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }

  tickerData(token: string | null): Promise<TickerDataResponse> {
    return axios
      .get<TickerDataResponse>(
        "https://beta.bcykal.com/mobycy/clientDash/get/data/ticker",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
  fleetGrownJourney(): Promise<FleetGrownJourneyResponse> {
    return this.instance
      .get<FleetGrownJourneyResponse>("get/data/fleet-growth/ev", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  fleetGrownJourneyDelivery(): Promise<FleetGrownJourneyResponse> {
    return this.instance
      .get<FleetGrownJourneyResponse>(
        "get/data/fleet-growth/deliveries",
        {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
}
