import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
  //Polygon
} from "@react-google-maps/api";
import moment from "moment";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import "./style.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Path } from "../3wTracker";
import { Link } from "@mui/material";
//import axios from 'axios';

function Map({
  paths,
}: {
  paths: Path[];
 
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [positionIndex, setPositionIndex] = useState(0);
  const [startAddress, setStartAddress] = useState<string | null>(null);
  const [endAddress, setEndAddress] = useState<string | null>(null);
  const [selectedMarkerstart, setSelectedMarkerstart] = useState<number | null>(null);
  const [selectedMarkerend, setSelectedMarkerend] = useState<number | null>(null);
  

  const googleMapApiKey = "AIzaSyAHzQG6CbgD3VdoChRyoYr8qbUm0ZRoBEg";
  
  let mapPaths: Path[] = paths;

  console.log('mapPaths', mapPaths)
  
  const mid = Math.floor(mapPaths.length / 2);
  const centerPathLat = mapPaths[mid]?.latitude;
  const centerpathLng = mapPaths[mid].longitude;
  const startLat = mapPaths[0].latitude;
  const startLng = mapPaths[0].longitude;
  const end = mapPaths.length - 1;
  const endPathLat = mapPaths[end].latitude;
  const endpathLng = mapPaths[end].longitude;
  const starttime = mapPaths[0].createdTime;
  const endtime = mapPaths[end].createdTime;

  const starttimeIST = moment.utc(starttime).tz("Asia/Kolkata");
  const endtimeIST = moment.utc(endtime).tz("Asia/Kolkata");

   let StartDate = starttimeIST.format("DD-MM-YYYY");
  let EndDate = endtimeIST.format("DD-MM-YYYY");

  let StartTime = starttimeIST.format("HH:mm:ss");
  let EndTime = endtimeIST.format("HH:mm:ss");
  console.log('centerPathLat', centerPathLat);
  console.log('centerpathLng', centerpathLng);


  

  const [mapPath, setMapPath] = useState<google.maps.LatLngLiteral[]>([]);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    const pathAsLatLngLiteral = convertPathToLatLngLiteral(paths);
    setMapPath(pathAsLatLngLiteral);
  }, [paths]);

  const convertPathToLatLngLiteral = (
    path: Path[]
  ): google.maps.LatLngLiteral[] => {
    return path.map((point) => ({
      lat: point.latitude,
      lng: point.longitude,
    }));
  };
 
  let iconMarkerStart = "https://zypp.app/clientdashboard-mailer/startpath.png";
  let iconMarkerEnd = "https://zypp.app/clientdashboard-mailer/endpath.png";

  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setPositionIndex((prevIndex) => {
          if (prevIndex === mapPath.length - 1) {
            setIsPlaying(false); // Stop playing if reached the end
            clearInterval(intervalId);
            return prevIndex;
          }
          return prevIndex + 1;
        });
      }, 1000); // Adjust the interval time as needed
    }

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [isPlaying, mapPath.length]);

  const fetchData = async () => {
    try {
      const fetchedallWorkAddress = await getLocality(
        googleMapApiKey,
        startLat,
        startLng
      );
      const fetchedallHomeAddress = await getLocality(
        googleMapApiKey,
        endPathLat,
        endpathLng
      );
      setStartAddress(fetchedallWorkAddress);

      setEndAddress(fetchedallHomeAddress);
     
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    console.log('mapPath', mapPath)
  }, []);
  async function getLocality(
    apiKey: string,
    latitude: number | undefined,
    longitude: number | undefined
  ): Promise<string | null> {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch address. Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        // Extract the name of the first locality and sublocality if available
        const localityComponentone = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_2")
        );
           const localityComponent = result.address_components.find(
          (component: any) => component.types.includes("sublocality_level_1")
        );
        const cityComponent = result.address_components.find((component: any) =>
          component.types.includes("locality")
        );
        const postalCodeComponent = result.address_components.find(
          (component: any) => component.types.includes("postal_code")
        );

        // Return the combined result of city, sublocality, and postal code if found
        if (localityComponent && cityComponent && postalCodeComponent && localityComponentone) {
          const localityone = localityComponentone.long_name;
          const locality = localityComponent.long_name;
          const city = cityComponent.long_name;
          const postalCode = postalCodeComponent.long_name;
          return `${localityone},${locality}, ${city}, ${postalCode}`;
        } else {
          // If any of the components is missing, return the formatted address
          return result.formatted_address;
        }
      } else {
        throw new Error(`No results found for the provided coordinates.`);
      }
    } catch (error: any) {
      console.error(`Error fetching locality: ${error.message}`);
      return null;
    }
  }
  const polylineOptionsBlue = {
    strokeColor: "#0826e5",
    strokeWeight: 2,
    strokeOpacity: 0.6,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 99999,
    icons: [
      {
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        },
        repeat: "40px",
        offset: "100%",
      },
    ],
  };
  const polylineOptions = {
    strokeColor: "#ffa500",
    strokeOpacity: 1,
    strokeWeight: 3,
    icons: [
      {
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
          scale: 3,
          fillColor: "#ffa500",
          fillOpacity: 1,
          strokeColor: "#ffa500",
        },
        offset: "100%",
      },
    ],
  };
 
  const handleMarkerStart = (e: any) => {
    setSelectedMarkerstart(e);
  };
  const handleMarkerEnd = (e: any) => {
    setSelectedMarkerend(e);
  };

  return (
    <div>
    
      <Card variant="outlined">
        <div className="gMapCont">
          <GoogleMap
            onLoad={(mapDetails: google.maps.Map) => {
              setMap(mapDetails); // Map object stored
            }}
            zoom={8}
            center={{ lat: centerPathLat, lng: centerpathLng }}
            options={{
              fullscreenControl: true,
              mapTypeControl: true,
              streetViewControl: true ,
            }}
            // journeydetails={journeydetails}
            mapContainerStyle={{
              width: "100%",
              height: "16.5rem",
            }}
          >
           

            <Polyline path={mapPath} options={polylineOptionsBlue} />

            {mapPath && (
              <>
                <Polyline
                  path={mapPath.slice(0, positionIndex + 1)}
                  options={polylineOptions}
                />

              </>
            )}
             <Marker
              // label={new window.google.maps.Label("")}
              icon={iconMarkerStart}
              position={{ lat: startLat, lng: startLng }}
              onClick={() => handleMarkerStart(mapPaths)}

            />
             {selectedMarkerstart !== null && (
              <InfoWindow
                options={{ pixelOffset: new window.google.maps.Size(0, -100) }}
                onCloseClick={() => setSelectedMarkerstart(null)}
                position={{
                  lat: startLat,
                  lng: startLng,
                }}
              >
                <div>
                  <p>
                    <b>{StartDate} {StartTime}</b>
                  </p>
                  <p>
                    <b>Work: </b>
                    {startAddress || "Unknown Address"}
                  </p>
                  <p>
                    <b>Latitude:</b> {startLat} <b>Longitude:</b> {startLng}
                  </p>
                  <p>
                    <b>Google Maps Link:</b>{" "}
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${startLat},${startLng}`}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      https://maps.google.com/?q={startLat},{startLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
            )}
            <Marker
              // name={"SOMA"}
              icon={iconMarkerEnd}
              position={{ lat: endPathLat, lng: endpathLng }}
              onClick={() => handleMarkerEnd(mapPaths)}

            />
            {selectedMarkerend !== null && (
              <InfoWindow
                options={{ pixelOffset: new window.google.maps.Size(0, -100) }}
                onCloseClick={() => setSelectedMarkerend(null)}
                position={{
                  lat: endPathLat,
                  lng: endpathLng,
                }}
              >
                <div>
                  <p>
                    <b>{EndDate} {EndTime}</b>
                  </p>
                  <p>
                    <b>Work: </b>
                    {endAddress || "Unknown Address"}
                  </p>
                  <p>
                    <b>Latitude:</b> {endPathLat} <b>Longitude:</b> {endpathLng}
                  </p>
                  <p>
                    <b>Google Maps Link:</b>{" "}
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${endPathLat},${endpathLng}`}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      https://maps.google.com/?q={endPathLat},{endpathLng}
                    </a>
                  </p>
                </div>
              </InfoWindow>
            )}
           
           

          
           
          </GoogleMap>
        </div>
      </Card>
    </div>
  );
}

// const WrappedMap = withScriptjs(withGoogleMap(Map));

export default Map;
