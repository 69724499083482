import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  createHubListResponse,
  createHubResponse,
} from "../@Types/DRO/ApiResponses";
import {
  createHubRequestBody,
  hubStatusRequestBody,
} from "../@Types/DTO/CreateHubRequest";
export class CreateHubApi {
  public static api = new CreateHubApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: "https://beta.bcykal.com/mobycy/clientDash/",
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
  getToken() {
    return localStorage.getItem("access_token");
  }

  addHubRequest(hubRequest: createHubRequestBody): Promise<AxiosResponse<any>> {
    const data = { hubRequest };
    const config = {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    };
    return this.instance.post<any>("create/hub", data, config);
  }
  createHubList(
    page: number,
    pageSize: number
  ): Promise<createHubListResponse> {
    return this.instance
      .get<createHubListResponse>("get/client/hubs", {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page: page,
          pageSize: pageSize,
        },
      })
      .then((e) => {
        return e.data;
      });
  }
  hubStatusUpdate(
    hubRequest: hubStatusRequestBody
  ): Promise<AxiosResponse<any>> {
    const data = { hubRequest };
    const config = {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    };
    return this.instance.post<any>("update/hub/status", data, config);
  }
}
